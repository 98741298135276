import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 78,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Poppins, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#006B3B",
      light: "#B1DAA9",
      dark: "#006B3B",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#1E1F1E",
      light: "#1E1F1E",
      dark: "#1E1F1E",
    },
    tertiary: {
      main: "#F3FAED",
    },
    text: {
      primary: "#006B3B",
      secondary: "#659179",
      content: "#000000",
      tertiary: "#FFFFFF",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
      link: "#659079",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Poppins", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
